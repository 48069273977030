'use client'

import { useMemo } from 'react'
import { usePathname } from 'next/navigation'
import { useBreakpoint } from '@/hooks/useBreakPoint'
import { Nav } from './nav'
import { FooterNav } from './nav/footer'
import { useCachedMyProfile } from '@/hooks/useMyProfile'
import { useAtomValue } from 'jotai'
import { surveyCompleteAtom } from '@/atoms'
import { useCachedSwitches } from '@/hooks/useSwitches'
import useNavLinks from '@/hooks/useNavLinks'
import WithAuth0 from '../with-auto0'
import useFooterLinks from '@/hooks/useFooterLinks'

const PAGES_WITHOUT_SIDEBAR = [
  {
    reg: /^\/(auth)(\/\S*)?$/,
    key: 'auth',
  },
  {
    reg: /^\/playground$/,
    key: 'playground',
  },
]

export interface SidebarProps {
  className?: string
}

export default function Sidebar({ className }: SidebarProps) {
  const pathname = usePathname()
  const { isBelowMd } = useBreakpoint('md')
  const { data: navLinks } = useNavLinks()
  const { data: footerLinks } = useFooterLinks()

  const hasSidebar = useMemo(() => {
    return !PAGES_WITHOUT_SIDEBAR.some(({ reg }) => reg.test(pathname))
  }, [pathname])

  const activeKey = useMemo(() => {
    const target = [...navLinks, ...footerLinks].find(
      (link) =>
        link.href &&
        ((pathname.includes(link.href) &&
          (link.href !== '/' || pathname === link.href)) ||
          (link.href === '/explore' && pathname === '/')),
    )
    return target?.key
  }, [pathname, navLinks, footerLinks])

  const { data: profile } = useCachedMyProfile()
  const surveyComplete = useAtomValue(surveyCompleteAtom)

  const { data: switches, isValidating: switchesLoading } = useCachedSwitches()

  const showSurvey =
    profile &&
    !profile?.survey_completed &&
    surveyComplete === null &&
    switches?.onboarding_survey &&
    !pathname?.startsWith('/auth/')

  const sideBarHide =
    !hasSidebar || showSurvey || (!switchesLoading && !switches)

  if (sideBarHide) {
    return null
  }

  const NavComp = isBelowMd ? FooterNav : Nav
  className = `${className || ''} ${sideBarHide ? 'invisible' : ''}`
  return (
    <WithAuth0>
      <NavComp className={className} activeKey={activeKey} />
    </WithAuth0>
  )
}
