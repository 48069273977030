import { getNotifications, getUnreadNotifications } from '@/service/notification.service'
import useService, { useCachedService } from '@banyudu/use-service'
import useInfinite from './useInfinite'
import { useAtomValue } from 'jotai'
import { readedNotificationIdsAtom, unreadNotificationCountRefreshKeyAtom } from '@/atoms'
import { useAuthedCachedService } from './useAuthService'
import { Notification } from '@/types'
import { useEffect, useMemo, useRef } from 'react'

export const useCachedNotifications = useCachedService(getNotifications)
const useNotifications = useService(getNotifications, undefined, {
  refreshInterval: 1000 * 60 * 5, // 5 minutes
  keepPreviousData: true,
})

export const useInfiniteNotifications = useInfinite(useNotifications)

const _useCachedUnreadNotifications = useAuthedCachedService(getUnreadNotifications, undefined, {
  refreshInterval: 1000 * 60 * 5, // 5 minutes
  keepPreviousData: true,
})

export const useUnreadNotifications = () => {
  const { data: notificationRes, isValidating, refresh } = _useCachedUnreadNotifications()
  const readedNotificationIds = useAtomValue(readedNotificationIdsAtom)
  const unreadNotificationCountRefreshKey = useAtomValue(unreadNotificationCountRefreshKeyAtom)

  const refreshTimer = useRef<NodeJS.Timeout>()

  useEffect(() => {
    if (refreshTimer.current) {
      clearTimeout(refreshTimer.current)
    }
    refreshTimer.current = setTimeout(() => {
      refresh()
    }, 100)
  }, [unreadNotificationCountRefreshKey])

  const notifications = useMemo(() => {
    return (notificationRes?.records ?? []).filter((item: Notification) => !readedNotificationIds.includes(item._id))
  }, [notificationRes, readedNotificationIds])

  const readedCount = useMemo(() => {
    return notificationRes?.records?.length - notifications.length
  }, [notificationRes, notifications])

  return {
    data: notifications,
    total: notificationRes?.total - readedCount,
    isValidating,
  }
}

export const useUnreadNotificationsCount = () => {
  const { total } = useUnreadNotifications()
  return total ?? 0
}
