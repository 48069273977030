'use client'

import { VALID_PARTERS } from '@/constants'
import { getInitialPartner, setSessionStorage } from '@/utils'
import { atom, useAtom } from 'jotai'
import { useCallback } from 'react'

const partnerAtom = atom<string | null>(getInitialPartner())

export default function usePartner() {
  const [partner, setPartner] = useAtom(partnerAtom)

  const handleSetPartner = useCallback(
    (partner: string) => {
      if (!VALID_PARTERS.includes(partner)) {
        setPartner(null)
        return
      }

      setPartner(partner)
      setSessionStorage('partner', partner)
    },
    [setPartner],
  )

  return {
    partner,
    setPartner: handleSetPartner,
  }
}
