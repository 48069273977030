import { cls } from '@/utils'
import NextLink, { LinkProps } from 'next/link'
import { ComponentPropsWithoutRef } from 'react'

export default function Link({
  className,
  ...props
}: LinkProps & ComponentPropsWithoutRef<'a'>) {
  return (
    <NextLink className={cls(className, 'hover:no-underline')} {...props} />
  )
}

export type { LinkProps }
